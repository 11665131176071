.SubVideoContainers{
    width: 100%;
    height: 34vh;
    position: relative;
}

.DesktopVideo{
    width: 100%;
    height: 100%;
    background-color: black;
}

.DesktopVideoPreview{
    width: 100%;
    height: 100%;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DesktopVideoSubtitles{        
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px;
    top: 0px;
    z-index: 1;
}

.MobileVideo{
    width: 0px;
    height: 0px;
    position: absolute;
    top: -1000px;
    background-color: black;
}

.WeCreateSectionContainer .SubHeading{
    font-size: 1.5rem;
    text-align: center;
    font-weight: normal;
}