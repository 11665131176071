.WeCreateSectionContainer{
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: auto;
    justify-items: center;
    justify-content: center;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    position: relative;
}

.WeCreateSectionContainer .SubHeading{
    font-size: 1.5rem;
    text-align: center;
    font-weight: normal;
}

.WeCreateBackButton{
    font-size: 1.5rem;
    text-align: center;
    font-weight: normal;
    width: 100%;
    height: 1.5rem;
    padding-bottom: 2vh;
}

.LoadingText{
    font-size: 3rem;
    text-align: center;
}

@media only screen and (max-width: 600px) {

    .LoadingText{
        font-size: 1.5rem;
        text-align: center;
    }
    

}
