.Navbar{
    z-index: 10000;
    position: sticky;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 96%;
    height: 120px;
    top:0px;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
}

.PageLinksContainer{
    display: flex;
    justify-content: space-between;
    width: 60%;
    height: fit-content;
    align-items: center;
}

.SocialLinksContainer{
    width: 20%;
    display: flex;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
}

.HamburgerIcon{
    position: absolute;
    visibility: hidden;
    top: -1000px;
}

.PageLinks{
    font-weight:bold;
}

.SocialLinks{
    width: 3rem;
    height: 3rem;
}

.MainLogo{
    height: 4rem;
}

a{
    height: fit-content;
    text-align: center;
}

.MainLogoContainer{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.MainLogoText{
    font-size: 13px;
    font-weight: 600;
}

.NavbarBackground{
    position: fixed;
    width: 100%;
    height: 120px;
    top:0px;
    left:0px;
    z-index: -3;
}

.NavbarBackgroundColour{
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to right, red, #E37F6C);
    opacity: 80%;
}

.NavbarBackgroundNoise{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1000px) {
    
    .SocialLinksContainer{
        position: absolute;
        visibility: hidden;
        top: -1000px;
    }

    .PageLinks{
        position: absolute;
        visibility: hidden;
        top: -1000px;
    }

    .HamburgerIcon{
        position: relative;
        visibility: visible;
        top: auto;
        height: 3rem;
        stroke: white;
        fill: white;
    }

}

.CollapsableMenu{
    position: absolute;
    visibility: hidden;
    top: -1000px; 
}

.CollapsableMenuNavbarBackground{
    position: fixed;
    width: 100%;
    height: 100vh;
    top:-10000px;
    left:0px;
}

.Active{
    position: absolute;
    top: 0px;
    left: 0px;
    visibility: visible;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20%;
    animation: fadeIn 1s;
}

.ActiveBackground{
    top:0px;
}

.ExitIcon{
    height: 3rem;
    z-index: 1;
}

.ExitIcon line{
    stroke: white;
    stroke-width: 10;
}

.ExitMenuContainer{
    display: flex;
    width: 98%;
    justify-content: flex-end;
    align-items: center;
    min-height: 120px;
    z-index: 1;
}

.CollapsableMenuPageLinks{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    z-index: 1;
}

.CollapsableMenuSocialLinks{
    display: flex;
    justify-content: center;
    gap: 1rem;
    z-index: 1;
}

.CollapsableMenuSocialLink{
    height: 3rem;
    z-index: 1;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }