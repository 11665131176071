.CursorOne{

    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    z-index: 100000;
    


    background-color: white;

    pointer-events: none 

}

.CursorTwo{

    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    z-index: 100000;
    
    transition-duration: 100ms;
    transition-timing-function: ease-out;
    -webkit-transition-timing-function: ease-out;

    background-color: white;

    opacity: 40%;

    pointer-events: none 
}

.CursorThree{

    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    z-index: 100000;
    
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    -webkit-transition-timing-function: ease-out;

    background-color: white;

    opacity: 30%;

    pointer-events: none 

}


@media only screen and (max-width: 700px) {

    .CursorOne{
        display: none;
    }
    
    .CursorTwo{
        display: none;
    }
    
    .CursorThree{
       display: none;
    }
        
  
}

.active{
    animation-duration: 1000ms;
    animation-name: active;
    background-color: black;
}

@keyframes active{
    0%   {background-color: white;}
    100% {background-color: black;}
  }