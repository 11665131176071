.MainVideoReelContainer{
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 8rem;
    position: sticky;
    top: 120px;
    z-index: 1000;
    pointer-events: none;

}

.MainVideoReelContainer video{
    width: 100%;
    height: 100%;
    background-color: black;
    object-fit: fill;
}