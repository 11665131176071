.WhatWeDoSectionContainer{
    width: 100%;
}

.DescriptionTextContainer{
    display: flex;
    justify-content: center;
}

.DescriptionText{
    font-size: x-large;
    text-align: center;
    width: 70%;
}

.DescriptionText .bolded{
    font-weight: bold;
}

@media only screen and (max-width: 600px) {

    .DescriptionText{
        font-size: large;
    }
    

}

@media only screen and (max-width: 450px) {

    .DescriptionText{
        font-size: medium;
    }
    

}