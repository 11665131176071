.ContentSectionContainer{
    width: 100%;
}

.ContentSectionContainer .Heading{
    font-size: 3rem;
    text-align: center;
    font-weight: 900;
    letter-spacing: .8rem;
}

.anchor{
    position: relative;
    top: -80px;
    visibility: hidden;
}

@media only screen and (max-width: 600px) {

    .ContentSectionContainer .Heading{
        font-size: 2rem;
    }

    .anchor{
        top: -100px;
    }

    .ContentSectionContainer .Heading{
        margin: 50px auto;
    }

}

@media only screen and (max-width: 500px) {

    .ContentSectionContainer .Heading{
        font-size: 1.5rem;
    }

    .anchor{
        top: -100px;
    }

}