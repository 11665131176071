.ContactUsSectionContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    
}

.ContactUsSectionDetails{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.ContactUsSectionImage{
    
}

.ContactUsSectionImage img{
    height: 100%;
    min-height: 400px;
    width: 100%;
    object-fit: cover;
}

.ContactUsSectionText{
    display: flex;
    text-align: center;
    width: 100%;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    font-size: 2rem;
}

.ContactUsSectionText span{
    font-weight: 900;
}

.ContactUsSectionText a{
    font-weight: 900;
}

.ContactUsMap{
    width: 100%;
    height: 400px;
    pointer-events: none;
}

@media only screen and (max-width: 450px) {

    .ContactUsSectionText{
        font-size: 1.2rem;
        height: auto;
    }

}
