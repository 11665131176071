.WordCloudWord{
    display: block;
    padding: 0.125rem 0.25rem;
    position: relative;
    text-decoration: none;
    font-size: calc(var(--size) * 0.40rem + 1rem);
    opacity: var(--opacity);
}

@media only screen and (max-width: 500px) {
    
    .WordCloudWord {
        font-size: calc(var(--size) * 0.15rem + 0.5rem);      
    }
}

.WordCloudWord[data-weight="1"] { --size: 3; }
.WordCloudWord[data-weight="2"] { --size: 3.5; }
.WordCloudWord[data-weight="3"] { --size: 3.8; }
.WordCloudWord[data-weight="4"] { --size: 4; }
.WordCloudWord[data-weight="5"] { --size: 6; }
.WordCloudWord[data-weight="6"] { --size: 8; }
.WordCloudWord[data-weight="7"] { --size: 10; }
.WordCloudWord[data-weight="8"] { --size: 13; }
.WordCloudWord[data-weight="9"] { --size: 16; }

.WordCloudWord[opacity="1"] { --opacity: 10%; }
.WordCloudWord[opacity="2"] { --opacity: 30%; }
.WordCloudWord[opacity="3"] { --opacity: 40%; }
.WordCloudWord[opacity="4"] { --opacity: 45%; }
.WordCloudWord[opacity="5"] { --opacity: 50%; }
.WordCloudWord[opacity="6"] { --opacity: 55%; }
.WordCloudWord[opacity="7"] { --opacity: 58%; }
.WordCloudWord[opacity="8"] { --opacity: 60%; }
.WordCloudWord[opacity="9"] { --opacity: 100%; }