.OurClientsSectionContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    --margin-top: 20vh;
}

.ClientLogoGrid{
    width: 70%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-items: center;
    row-gap: 20px;
    column-gap: 12px;
}


.ClientLogoGrid img{
    width: 200px;
    height: 150px;
    object-fit: contain;
}
