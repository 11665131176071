
.WordCloud{
    width: 100%;
    display: flex;
    justify-content: center;
}

.WordCloud ul{
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    line-height: 4.5rem;
    width: 80%;
}

@media only screen and (max-width: 500px) {

    .WordCloud ul{
        line-height: 2rem;
    }
    
}