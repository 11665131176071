.BackgroundNoiseContainer{
    width: 100vw;
    height: 100vh;
    position:fixed;
    top: 0px;
    left: 0px;
    z-index: -2;
}

.BackgroundNoiseContainer svg{
    width: 100%;
    height: 100%;
}

.BackgroundNoiseContainer .BackgroundColour{
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to right, red, #E37F6C);
    opacity: 80%;
}



